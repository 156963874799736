const statusColor = {
    complete: 'success',
    inprogress: 'primary',
    pending: 'warning',
    action: 'error',
    transfer: 'error',
    success: 'success',
    warning: 'warning',
    transmitting: 'primary',
    failed: 'error',
    null: 'error',
}

export default statusColor

// complete: 'primary',
// inprogress: 'success',
// pending: 'warning',
// action: 'error',
// transfer: 'error',
// success: 'success',
// warning: 'warning',
// transmitting: 'secondary',
// failed: 'error',
// null: 'error',
